<template>
  <div class="d-flex flex-column align-items-center mt-32">
    <b-card class="bg-transparent w-50">
      <b-alert :show="error === 'error'" variant="danger"
        >{{$t('SHOP_ORDER.INVALID_LINK')}}</b-alert
      >
      <b-alert :show="error === 'already_paid'" variant="danger"
        >{{$t('SHOP_ORDER.ALREADY_PAID')}}</b-alert
      >
      <b-alert :show="error === 'timeout'" variant="danger">
        {{$t('SHOP_ORDER.TIMEOUT')}}
      </b-alert>

    </b-card>

  </div>
</template>

<style lang="scss" scoped></style>
<script>
import axios from 'axios';
import PaymentComplete from '@/view/components/PaymentComplete.vue';
import { toasts } from '@/core/mixins/toastr-helper.mixin.js';

export default {
  name: 'resume-payment-session',
  mixins: [ toasts ],
  props: [],
  components: {
    PaymentComplete
  },
  data() {
    return {
      shop_order_id: null,
      token: null,
      error: null,
      shop_order: null
    };
  },
  mounted() {
    this.token = this.$route.params.token;
    this.shop_order_id = this.$route.params.shop_order_id;
    
    this.postCreateSession(this.shop_order_id, this.token);
  },
  watch: {},
  methods: {

    
    postCreateSession(shop_order_id, token) {
      const loader = this.$loading.show();
    
      axios 
        .post(`/shop_order/create_dintero_session/${shop_order_id}`, { token: token })
        .then(res => {
          loader.hide();

          if (res.status === 201) {
            const shop_order = res.data;

            if (shop_order.is_ticket) {
              this.$router.push(`/event-payment/${shop_order.shop_order_id}/${shop_order.dintero_id}/${shop_order.token}`);
            }
            else if (shop_order.is_renew) {
              this.$router.push(`/renewpayment/${shop_order.renew_id}/${shop_order.dintero_id}/${shop_order.token}`);
            }
            else {
              this.$router.push(`/payment/${shop_order.shop_order_id}/${shop_order.dintero_id}/${shop_order.token}`);
            }
            
          }
          else if (res.status === 200) {
            console.error('dintero session unable to create');

            const shop_order = res.data;

            if (shop_order.is_ticket) {
              this.$router.push(`/event-payment/${shop_order.shop_order_id}/${shop_order.dintero_id}/${shop_order.token}`);
            }
            else if (shop_order.is_renew) {
              this.$router.push(`/renewpayment/${shop_order.renew_id}/${shop_order.dintero_id}/${shop_order.token}`);
            }
            else {
              this.$router.push(`/payment/${shop_order.shop_order_id}/${shop_order.dintero_id}/${shop_order.token}`);
            }
          }
          else if (res.status === 409) {
            this.error = 'already_paid';
          }
          else {
            this.error = 'error';
          }
        })
        .catch(err => {
          loader.hide();
          console.error('err', err);
          this.toastr('danger', this.$t('COMMON.ERROR'), 'Felaktig betallänk');
          this.error = 'error';
        });
    },



  }
};
</script>
